<div class="flex border-b border-gray-200">
  <button
    *ngFor="let tab of tabs; let i = index"
    (click)="selectTab(i)"
    [ngClass]="{
      'border-b-2 border-mango text-mango': selectedTab === i,
      'text-gray-500': selectedTab !== i
    }"
    class="px-4 py-2 focus:outline-none"
  >
    {{ tab.label }}
  </button>
</div>

<div class="">
  <ng-container *ngFor="let tab of tabs; let i = index">
    <div *ngIf="selectedTab === i">
      <ng-container *ngTemplateOutlet="tab.contentTemplate"></ng-container>
    </div>
  </ng-container>
</div>
